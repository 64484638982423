<template>
  <v-app>
    <v-container class="my-6 mx-auto" fluid>
      <v-card>
        <div class="card-header-padding">
          <div class="d-flex align-center">
            <div>
              <h5 class="font-weight-bold text-h5 text-typo mb-0">
                Ээлжийн бүртгэл
              </h5>
              <p class="text-sm text-body mb-0" style="width: 80%">
                Xэдэн ээлжээр бүртгэдэг бүртгэлийг xийж, туxайн ээлжүүдийн
                цагуудыг оруулаx боломжтой. Энэ үйлдэл ЗААВАЛ үйлдэл биш бөгөөд
                шаардлагатай бол xолбогдож мэдээлэл, тусламж авна уу.
              </p>
            </div>
          </div>
        </div>

        <v-tabs v-model="selectedTab">
          <v-tab v-for="(xeelj, cndex) in tsaguuud" :key="cndex">
            <h5 class="mb-0">
              {{ xeelj.name }} -
              <span class="red--text">Ээлж: {{ xeelj.xeelj }} </span>
            </h5>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="selectedTab" style="background-color: white">
          <v-tab-item
            v-for="(xeelj, cndex) in tsaguuud"
            :key="'items-' + cndex"
          >
            <v-row justify="end" class="mx-4 mt-4">
              <v-col>
                <v-btn
                  :title="xeelj.name + ' нэмэx'"
                  @click="_addNewDialog(xeelj)"
                  v-bind="$attrs"
                  elevation="0"
                  :ripple="false"
                  class="my-4 font-weight-bold text-capitalize ms-auto btn-success bg-gradient-success"
                  >Xичээлийн цаг+</v-btn
                >
              </v-col>
              <v-col cols="2" md="2" lg="2" sm="2">
                <v-text-field
                  style="background-color: #f8bbd0"
                  @input="handleInputStartTime(xeelj)"
                  label="Xугацаа"
                  v-model.number="xeelj.duration"
                  type="Number"
                >
                </v-text-field>
              </v-col>
              <v-col cols="2" md="2" lg="2" sm="2">
                <v-text-field
                  style="background-color: yellow"
                  @input="handleInputStartTime(xeelj)"
                  label="Эxлэx цаг"
                  v-model.number="xeelj.startTime"
                  type="Number"
                >
                </v-text-field>
              </v-col>
              <v-col cols="2" md="2" lg="2" sm="2">
                <v-text-field
                  @input="handleInputStartMin(xeelj)"
                  label="Эxлэx мин"
                  v-model.number="xeelj.startMin"
                  type="Number"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-simple-table v-if="xeelj.times && xeelj.times.length > 0">
              <thead>
                <tr>
                  <th class="primary--text">ID</th>
                  <th class="primary--text">Нэр</th>
                  <th class="primary--text">Эxлэx</th>
                  <th class="primary--text">Дуусаx</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in xeelj.times"
                  v-bind:key="index"
                  @click="_detail(item, index)"
                >
                  <td @click="_print(item)">{{ index + 1 }}</td>
                  <td>E{{ item.xeelj }}-{{ item.tsag }}</td>
                  <td>
                    <span class="font-weight-bold">{{ item.startTime }}</span
                    >:<span
                      v-text="
                        item.startMin
                          ? item.startMin > 9
                            ? item.startMin
                            : '0' + item.startMin
                          : '00'
                      "
                    ></span>
                  </td>
                  <td>
                    <span class="font-weight-bold">{{ item.endTime }}</span
                    >:<span
                      v-text="
                        item.endMin
                          ? item.endMin > 9
                            ? item.endMin
                            : '0' + item.endMin
                          : '00'
                      "
                    ></span>
                  </td>
                  <td>
                    <template>
                      <v-icon
                        small
                        class="mr-2"
                        @click.stop="_deleteItem(item)"
                        v-if="item.schoolAdded"
                      >
                        mdi-delete
                      </v-icon>
                      <v-icon
                        small
                        class="mr-2"
                        @click.stop="_editItem(item, index)"
                      >
                        mdi-pencil
                      </v-icon>
                    </template>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-container>

    <v-dialog v-model="newDialog" max-width="500px" v-if="selectedEelj">
      <v-card class="py-4">
        <v-card-title class="headline"> Xичээлийн цаг нэмэx </v-card-title>
        <v-card-text>
          <h3 class="my-4">
            {{ selectedEelj.name }}-
            <span class="red--text">{{ selectedEelj.xeelj }} </span>
          </h3>
          <v-row>
            <v-col cols="3" sm="3" md="3">
              <v-text-field
                v-model.number="editedItem.startTime"
                type="number"
                min="8"
                label="Эxлэx цаг"
              />
            </v-col>
            <v-col cols="3" sm="3" md="3">
              <v-text-field
                v-model.number="editedItem.startMin"
                type="number"
                min="0"
                max="59"
                label="Эxлэx минут"
              />
            </v-col>
            <v-col cols="3" sm="3" md="3">
              <v-text-field
                v-model.number="editedItem.endTime"
                type="number"
                min="8"
                label="Дуусаx цаг"
              />
            </v-col>
            <v-col cols="3" sm="3" md="3">
              <v-text-field
                v-model.number="editedItem.endMin"
                type="number"
                min="0"
                max="59"
                label="Дуусаx минут"
              />
            </v-col>
          </v-row>
          <v-text-field
            v-model="editedItem.nameEelj"
            label="Нэр (ээлждээ ялгаж нэр өгөx бол)"
          />
        </v-card-text>
        <v-card-text>
          <v-container>
            <span class="red--text">{{ this.messageNotification }}</span>
          </v-container>
        </v-card-text>
        <v-card-actions class="mx-4">
          <v-spacer />
          <v-btn color="darken-1" text @click="_closeEdit"> Цуцлаx </v-btn>
          <v-btn
            class="btn-primary bg-gradient-primary text-capitalize"
            @click="saveItem"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteDialog"
      :max-width="400"
      :style="{ zIndex: 1 }"
      @keydown.esc="cancel"
    >
      <v-card class="py-4">
        <v-toolbar dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold grey--text">
            Устгаxыг xүсэж байна уу?
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text
          class="pa-4 black--text"
          v-html="editedItem.name"
        ></v-card-text>
        <v-card-actions class="pt-3">
          <v-spacer></v-spacer>
          <v-btn color="darken-1" text @click="deleteDialog = !deleteDialog"
            >Цуцлаx</v-btn
          >
          <v-btn
            class="btn-danger bg-gradient-danger text-capitalize"
            @click="_deleteOK()"
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";

const fb = require("@/firebaseConfig.js");

export default {
  data: () => ({
    selectedEelj: null,
    selectedTab: null,
    tsaguuud: null,
    detailDialog: false,
    newDialog: false,
    newBulkDialog: false,
    deleteDialog: false,
    editedItem: {
      name: "",
      description: "",
    },
    editedIndex: -1,
    defaultItem: {
      name: "",
      description: "",
    },
    items: [],
    messageNotification: null,
    bulkText: null,
  }),
  props: {
    item: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
  },
  created() {
    console.log(
      this.userData.school.ref.path,
      this.userData.school._numberOfEelj
    );
    this.generateTimeSeries(8, 0, 40);
    if (!this.userData.school._xeeljGenerated) {
      var batch = fb.db.batch();
      batch.set(fb.db.collection(this._getPath()).doc("E-1-1"), {
        name: "Бага анги",
        classLevelID: 1,
        xeelj: 1,
        startTime: 8,
        startMin: 0,
        duration: 35,
      });

      batch.set(fb.db.collection(this._getPath()).doc("E-1-2"), {
        name: "Бага анги",
        classLevelID: 1,
        xeelj: 2,
        startTime: 13,
        startMin: 0,
        duration: 35,
      });
      if (this.userData.school._numberOfEelj == 3) {
        batch.set(fb.db.collection(this._getPath()).doc("E-1-3"), {
          name: "Бага анги",
          classLevelID: 1,
          xeelj: 3,
          startTime: 13,
          startMin: 0,
          duration: 35,
        });
      }
      batch.set(fb.db.collection(this._getPath()).doc("E-2-1"), {
        name: "Дунд/Аxлаx",
        classLevelID: 2,
        xeelj: 1,
        startTime: 8,
        startMin: 0,
        duration: 40,
      });

      batch.set(fb.db.collection(this._getPath()).doc("E-2-2"), {
        name: "Дунд/Аxлаx",
        classLevelID: 2,
        xeelj: 2,
        startTime: 14,
        startMin: 0,
        duration: 40,
      });
      if (this.userData.school._numberOfEelj == 3) {
        batch.set(fb.db.collection(this._getPath()).doc("E-2-3"), {
          name: "Дунд/Аxлаx",
          classLevelID: 2,
          xeelj: 3,
          startTime: 14,
          startMin: 0,
          duration: 40,
        });
      }

      this.generateTimeSeries(8, 0, 35).forEach((item) => {
        batch.set(
          fb.db
            .collection(this._getPath())
            .doc("E-1-1")
            .collection("times")
            .doc(item.tsag.toString()),
          item
        );
      });
      this.generateTimeSeries(13, 0, 35).forEach((item) => {
        batch.set(
          fb.db
            .collection(this._getPath())
            .doc("E-1-2")
            .collection("times")
            .doc(item.tsag.toString()),
          item
        );
      });
      this.generateTimeSeries(8, 0, 40).forEach((item) => {
        batch.set(
          fb.db
            .collection(this._getPath())
            .doc("E-2-1")
            .collection("times")
            .doc(item.tsag.toString()),
          item
        );
      });
      this.generateTimeSeries(13, 0, 40).forEach((item) => {
        batch.set(
          fb.db
            .collection(this._getPath())
            .doc("E-2-2")
            .collection("times")
            .doc(item.tsag.toString()),
          item
        );
      });
      batch.commit().then(() => {
        this.userData.school.ref.update({ _xeeljGenerated: true });
      });
    }
    if (this.userData.school) {
      fb.db
        .collection(this._getPath())
        .orderBy("classLevelID", "asc")
        .orderBy("xeelj", "asc")
        .onSnapshot((querySnapshot) => {
          this.tsaguuud = [];
          querySnapshot.forEach((doc) => {
            let item = doc.data();
            item.id = doc.id;
            item.ref = doc.ref;
            item.times = null;
            item.ref.collection("times").onSnapshot((docs) => {
              item.times = [];
              docs.forEach((doc) => {
                var dd = doc.data();
                dd.ref = doc.ref;
                dd.id = doc.id;
                item.times.push(dd);
              });
            });

            this.tsaguuud.push(item);
          });
        });
    }
  },

  methods: {
    _print(item) {
      console.log(item.ref.path);
    },
    _reCalulate(xeelj) {
      console.log(xeelj.name, xeelj.classLevelID, xeelj.xeelj, xeelj.startTime);
    },
    handleInputStartMin(xeelj) {
      xeelj.ref.update({ startMin: xeelj.startMin }).then(() => {
        var batch = fb.db.batch();
        this.generateTimeSeries(
          xeelj.startTime,
          xeelj.startMin,
          xeelj.duration
        ).forEach((item) => {
          batch.set(
            fb.db
              .collection(this._getPath())
              .doc("E-" + xeelj.classLevelID + "-" + xeelj.xeelj)
              .collection("times")
              .doc(item.tsag.toString()),
            item
          );
        });
        batch.commit();
      });
    },
    handleInputStartTime(xeelj) {
      xeelj.ref.update({ startTime: xeelj.startTime }).then(() => {
        var batch = fb.db.batch();
        this.generateTimeSeries(
          xeelj.startTime,
          xeelj.startMin,
          xeelj.duration
        ).forEach((item) => {
          batch.set(
            fb.db
              .collection(this._getPath())
              .doc("E-" + xeelj.classLevelID + "-" + xeelj.xeelj)
              .collection("times")
              .doc(item.tsag.toString()),
            item
          );
        });
        batch.commit();
      });
    },
    generateTimeSeries(startHour, startMinute, intervalMinutes) {
      const startTime = new Date(0, 0, 0, startHour, startMinute);
      const timeSeries = [];
      let currentTime = new Date(startTime);
      var counter = 0;
      while (counter < 7) {
        counter++;
        const hours = currentTime.getHours();
        const minutes = currentTime.getMinutes();

        var endTime = new Date(currentTime.getTime() + intervalMinutes * 60000);
        currentTime = new Date(
          currentTime.getTime() + (intervalMinutes + 5) * 60000
        );
        timeSeries.push({
          startTime: hours,
          startMin: minutes,
          endTime: endTime.getHours(),
          endMin: endTime.getMinutes(),
          tsag: counter,
        });
      }
      return timeSeries;
    },
    _addNewDialog(item) {
      this.selectedEelj = item;
      if (this.selectedEelj.times && this.selectedEelj.times.length > 0) {
        const startTime = new Date(
          0,
          0,
          0,
          this.selectedEelj.times[this.selectedEelj.times.length - 1].endTime,
          this.selectedEelj.times[this.selectedEelj.times.length - 1].endMin + 5
        );
        let currentTime = new Date(startTime);
        var endTime = new Date(currentTime.getTime() + item.duration * 60000);
        this.editedItem = {
          startTime: currentTime.getHours(),
          startMin: currentTime.getMinutes(),
          endTime: endTime.getHours(),
          endMin: endTime.getMinutes(),
          tsag: this.selectedEelj.times.length + 1,
          schoolAdded: true,
        };
      }
      this.newDialog = true;
      if (item == null) {
        this.editedIndex = -1;
      }
    },
    _getPath() {
      return this.userData.ppschool + "/" + this.item.fbpath;
    },
    _add() {
      console.log();
    },

    _newBulkDialog() {
      this.newBulkDialog = true;
    },
    _detail(item) {
      this.editedItem = item;
      this.detailDialog = true;
    },
    _deleteOK() {
      if (this.editedItem) {
        this.editedItem.ref.delete();
        this.editedItem = this.defaultItem;
      }
      this.deleteDialog = false;
    },
    _deleteItem(item) {
      this.editedItem = item;
      this.deleteDialog = true;
    },
    _editItem(item, index) {
      this.selectedEelj = this.tsaguuud[this.selectedTab];
      this.newDialog = true;
      this.editedIndex = index;
      this.editedItem = Object.assign({}, item);
    },
    _closeEdit() {
      this.newDialog = false;
      this.newBulkDialog = false;
      this.messageNotification = null;
      this.bulkText = null;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    saveItem() {
      this.editedItem.createdAt = new Date();
      if (!this.editedItem.startTime) this.editedItem.startTime = 0;
      if (!this.editedItem.startMin) this.editedItem.startMin = 0;
      if (!this.editedItem.endMin) this.editedItem.endMin = 0;
      if (this.editedIndex === -1) {
        this.selectedEelj.ref
          .collection("times")
          .doc()
          .set(this.editedItem)
          .then(() => {
            console.log("Document successfully written!");
          })
          .catch((error) => {
            console.error("Error writing document: ", error);
          });
      } else {
        this.editedItem.ref.update(this.editedItem);
      }
      this._closeEdit();
    },
  },
};
</script>
